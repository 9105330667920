


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Grid } from '@/types/cms/components';

/**
 * CMS Grid component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSComponentGrid extends Vue {
    // Prop with grid data
    @Prop({type: Object as () => Grid, required: true}) readonly gridData: Grid | undefined;

    // Props for col count
    @Prop({ type: [Boolean, String, Number], required: false, default: false }) readonly cols!: boolean | string | number;
    @Prop({ type: [Boolean, String, Number], required: false, default: false }) readonly md!: boolean | string | number;

    // Props for offset
    @Prop([String, Number]) readonly offset: string | number | undefined;
    @Prop([String, Number]) readonly offsetMd: string | number | undefined;
}
